import React, { useState,useEffect,useRef } from 'react';
import { Card,Col, Row } from 'react-bootstrap';
import { AiFillCaretRight, AiFillCaretLeft} from "react-icons/ai";
import { transformToBNotation } from '../../helpers/utils';
import ReactCardFlip from 'react-card-flip';

function PlayerDetailCard({ cardData, cardSelection, formationType, formationIndex, cardFunction, roleId, isMobile}) {
  const [flipped, setFlipped] = useState(false);
  const [position, setPosition] = useState({scoreColor: "#FFFFFF"});

  var cardWidth = 220;
  var cardHeight = 180;
  var mobileRatioWd = 1.6;
  var mobileRatioVh = 1;
  if (isMobile){
    cardWidth = 60;
    cardHeight = 120;
  }
  
  const tiltRef = useRef();
  // console.log(cardData)
  
  useEffect(() => {
    // let position = formationHelper.calcPositionScore(formationType, formationIndex, cardData);
    // setPosition(position);
    // Initialize VanillaTilt on the DOM element using the ref
  }, [cardData]);

  // console.log(cardData, cardSelection)
  return (
    <>
    {cardData != undefined && (
    <>
    {!isMobile? (
    <ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
    {/* FRONT */}
    <Card ref={tiltRef} className="card-front" style={{borderColor: position.scoreColor, borderWidth: "3px" ,boxShadow: `0px 2px 10px rgba(0, 0, 0, 0.5)`, padding: '0%', width: cardWidth, height: cardHeight}}>
    <div style={{cursor: 'pointer'}} onClick={() => {handleSelectClick(cardData.tokenId)}} >
      <Card.Body style={{padding: '0px', paddingTop: "1%"}}>
        {/* <Card.Title style={{textAlign: "center"}}>{cardData.title}</Card.Title> */}
        <Row className="justify-content-center" style={{ paddingBottom: "1%"}}>
          <div style={{fontSize: '1rem', fontWeight: 'bold'}}>
            {cardData.title.startsWith('Baller #8453') ? "Baller #" + transformToBNotation(cardData.tokenId) : cardData.title}
          </div>
        </Row>
        <Row className="justify-content-center" style={{paddingBottom: "5%"}}>
          {/* {cardSelection !== cardData.tokenId && ( */}
            <Col md="auto" xs="auto" className="d-flex align-items-center">
              <Row className="justify-content-center">
                <AiFillCaretLeft onClick={() => {handleSelectClick(cardData.tokenId, "Back")}}/>
              </Row>
            </Col>
          {/* )} */}
          <Col md="auto" xs="auto">
            <Row className="justify-content-center">
              {cardData != undefined && (
              <img src={cardData.imgSrc} style={{height: "100px"}} />
              )}
            </Row>
          </Col>
          {/* {cardSelection !== cardData.tokenId && ( */}
            <Col md="auto" xs="auto" className="d-flex align-items-center">
              <Row className="justify-content-center">
                <AiFillCaretRight onClick={() => {handleSelectClick(cardData.tokenId, "Next")}}/>
              </Row>
            </Col>
          {/* )} */}
        </Row>
          {/* <Col md="auto" xs="auto">
            <Row className="justify-content-center">
              <div style={{fontSize: '0.9rem', fontWeight: 'bold'}}>
                {cardData.body}
              </div>
            </Row>
            <Row className="justify-content-center">
              <Col md="auto" xs="auto">
              <table style={{fontSize: '0.9rem', width:"100%",borderRadius:"15%", textAlign:"center"}}>
                <tbody>
                  {cardData.skillsName.slice(0, cardData.skillsName.length / 2).map((row, index) => (
                    <tr key={index}>
                      <td>{row}</td>
                      <td>{cardData.skillsValue[index] === 0 ? "N/A" : cardData.skillsValue[index]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </Col>
              <Col md="auto" xs="auto">
                <table style={{fontSize: '0.9rem', width:"100%",borderRadius:"15%", textAlign:"center"}}>
                  <tbody>
                    {cardData.skillsName.slice(Math.ceil(cardData.skillsName.length / 2)).map((row, index) => (
                      <tr key={index}>
                        <td>{row}</td>
                        <td>{cardData.skillsValue[index + Math.ceil(cardData.skillsName.length / 2)] === 0 ? "N/A" : cardData.skillsValue[index + Math.ceil(cardData.skillsName.length / 2)]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Col> */}
      </Card.Body>
      </div>
    </Card>
    {/* BACK */}
    <Card style={{boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.5)', padding: '0%', width: cardWidth, height: cardHeight}}>
      <Card.Body>
        {/* <Card.Title>{cardData.title}</Card.Title> */}
        <table style={{fontSize: '0.9rem', width:"100%",borderRadius:"15%", textAlign:"center"}}>
          <thead>
            <tr>
              <th>
                {cardData.body}
              </th>
            </tr>
          </thead>
          <tbody>
                <>
                  {cardData.skillsName.map((row, index) => (
                    <tr key={index}>
                      <td>{row}</td>
                      <td>{cardData.skillsValue[index] === 0 ? "N/A" : cardData.skillsValue[index]}</td>
                    </tr>
                  ))}
                </>
          </tbody>
        </table>
        {cardSelection !== undefined ? (
            <div style={{paddingTop: "5%"}}>
            {cardSelection > 0 ? (
              <>
                {cardSelection === cardData.tokenId ? (
                  <>
                  {/* <button className="button-formation-selected" onClick={() => handleSelectClick(cardData.tokenId)} style={{display: 'flex',margin:"auto", width:"85%",borderRadius:"15%", textAlign:"center"}} >Unselect</button> */}
                  </>
                ):(
                  <>
                  {/* <button className="button-formation" onClick={() => handleSelectClick(cardData.tokenId)} style={{display: 'flex',margin:"auto", width:"60%",borderRadius:"15%", textAlign:"center" }} >Swap</button> */}
                  </>
                )} 
              </>
              ) : (
                <>
                {/* <button className="button-formation" onClick={() => handleSelectClick(cardData.tokenId)} style={{display: 'flex',margin:"auto", width:"65%",borderRadius:"15%", textAlign:"center" }} >Select</button> */}
                </>
              )}
            </div>
        ):null}
      </Card.Body>
    </Card>
    </ReactCardFlip>
    ):(
      <ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
      {/* FRONT */}
      <Card ref={tiltRef} className="card-front" style={{boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.5)', padding: '0%', width: cardWidth*mobileRatioWd, height: cardHeight*mobileRatioVh}}>
        <div style={{ position: 'relative', height: '100%' }}>
          <div style={{ position: 'absolute', left: '5px', top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}>
            <AiFillCaretLeft onClick={(e) => {
              e.stopPropagation();
              handleSelectClick(cardData.tokenId, "Back");
            }}/>
          </div>
          
          <div style={{ position: 'absolute', right: '5px', top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}>
            <AiFillCaretRight onClick={(e) => {
              e.stopPropagation();
              handleSelectClick(cardData.tokenId, "Next");
            }}/>
          </div>
          
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
            <Card.Img variant="top" src={cardData.imgSrc} style={{ maxHeight: '80%', width: 'auto' }} />
            <Card.Title className="text-center" style={{ marginTop: 'auto' }}>
              <div className="sub-title-mobile" style={{fontSize: "0.8rem"}}>
                {cardData.title}
              </div>
            </Card.Title>
          </div>
        </div>
      </Card>
      {/* BACK */}
      <Card style={{boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.5)', padding: '0%', width: cardWidth*mobileRatioWd, height: cardHeight*mobileRatioVh}}>
        <Row className="justify-content-center" style={{ paddingBottom: "1%"}}>
          <div style={{fontSize: '1rem', fontWeight: 'bold'}}>
            {cardData.title}
          </div>
        </Row>
      </Card>
      </ReactCardFlip>
    )}
    </>
    )}
    </>
  );
  
  function handleSelectClick(tokenId, side){
    cardFunction(roleId, tokenId, side)
  }
}

export default PlayerDetailCard;