import React from 'react';
import { useTransition, animated } from '@react-spring/web';
import './Notification.css';

export const Notification = ({ notifications, removeNotification }) => {
  const transitions = useTransition(notifications, {
    from: { 
      opacity: 0, 
      transform: 'translateX(100%)',
      life: '100%'  // Start with full progress bar
    },
    enter: [
      // First quickly show the notification
      { 
        opacity: 1, 
        transform: 'translateX(0%)',
      },
      // Then animate the progress bar
      { 
        life: '0%'  // Animate to empty progress bar
      }
    ],
    leave: { 
      opacity: 0, 
      transform: 'translateX(100%)',
    },
    config: (item, index, phase) => 
      // Fast animation for enter/leave, slow for progress bar
      phase === 'enter' && index === 1 
        ? { duration: 1000 }  // Progress bar duration
        : { tension: 125, friction: 20, precision: 0.1 },  // Quick transitions
    onRest: (result, ctrl, item) => {
      if (!result.cancelled && item) {
        removeNotification(item.id);
      }
    },
  });

  return transitions((style, item) => (
    <animated.div 
      className="notification-container"
      style={{
        opacity: style.opacity,
        transform: style.transform
      }}>
      {item.message}
      <animated.div 
        className="progress-bar"
        style={{ 
          right: style.life
        }} 
      />
    </animated.div>
  ));
};
