import React, { useState,useEffect,useRef } from 'react';
import { Card,Col, Row } from 'react-bootstrap';
import VanillaTilt from 'vanilla-tilt';
import ReactCardFlip from 'react-card-flip';
import { transformToBNotation } from '../../helpers/utils';
import RoseChart from '../charts/rose/RoseChart';

function PlayerCardMarket({ baseUrl,cardData, isOwned, roseColor, isMobile}) {
  const [flipped, setFlipped] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  // Default card size
  const cardWidth = 220;
  const cardHeight = 165;

  const tiltRef = useRef();
  // console.log(cardData.title, cardData.body, cardData.imgSrc,cardSelection)
  useEffect(() => {
    // Initialize VanillaTilt on the DOM element using the ref
    VanillaTilt.init(tiltRef.current, {
      max: 10, // Maximum tilt angle
      speed: 400, // Tilt speed
      glare: true, // Enable glare effect
      startY: 10,
      'max-glare': 0.5 // Max glare opacity
    });

  }, []);

  function initVanillaTilt(){
    //set timeout to wait for the flip animation to finish
    setTimeout(() => {
      VanillaTilt.init(tiltRef.current, {
        max: 25, // Maximum tilt angle
        speed: 400, // Tilt speed
        glare: true, // Enable glare effect
        startY: 10,
        'max-glare': 0.5 // Max glare opacity
      });
    }, 500);
  }

  function handleSellClick(tokenId) {
    let url = `${baseUrl}/${tokenId}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
    {/* FRONT */}
    <Card 
      ref={tiltRef} 
      className="card-front" 
      style={{
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.5)', 
        padding: '0%', 
        width: isMobile ? 'auto' : cardWidth, 
        height: isMobile ? 'auto' : cardHeight,
        transition: 'height 0.5s ease',
        transformOrigin: 'top',
        display: isMobile ? 'flex' : 'block',
        flexDirection: isMobile ? 'column' : 'initial',
        justifyContent: isMobile ? 'center' : 'initial',
        alignItems: isMobile ? 'center' : 'initial'
      }}
      onMouseEnter={e => {
        e.currentTarget.style.height = `${cardHeight + 50}px`;
        e.currentTarget.style.marginBottom = '-50px';
        setIsHovered(true);
      }}
      onMouseLeave={e => {
        e.currentTarget.style.height = `${cardHeight}px`;
        e.currentTarget.style.marginBottom = '0px';
        setIsHovered(false);
      }}
    >
      <Card.Title style={{
        fontSize: "0.8rem", 
        textAlign: "center",
        width: isMobile ? '100%' : 'auto'
      }}>
          {cardData.title.startsWith('Baller #8453') ? "Baller #" + transformToBNotation(cardData.tokenId) : cardData.title}
        </Card.Title>
      <Row>
        <Col xs={6} md={6}>
          <Card.Img 
            variant="top" 
            src={cardData.imgSrc} 
          />
        </Col>
        <Col xs={6} md={6} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
          <RoseChart playersData={[cardData]} showLabels={false} showLegend={false} customColors={{
            backgroundColors: roseColor ? roseColor.backgroundColors : null,
            borderColors: roseColor ? roseColor.borderColors : null
          }}/>
        </Col>
      </Row>
      {isHovered && (
        <Row className="justify-content-center" style={{paddingTop:"4%"}}>
          <Col md="auto" style={{width: "80%"}}>
            <button 
              className="button-formation" 
              onClick={() => cardData.isOnEthereum ? handleSellClick(cardData.tokenId) : null} 
              style={{ 
                width:"100%",
                borderRadius:"15%", 
                textAlign:"center", 
                opacity: isHovered ? 1 : 0,
                transition: 'opacity 0.5s ease',
                backgroundColor: cardData.isOnEthereum ? '' : '#808080',
                cursor: cardData.isOnEthereum ? 'pointer' : 'not-allowed'
              }} 
            >
              <div className="sub-title-mobile" style={{color: cardData.isOnEthereum ? '' : '#A9A9A9'}}>
                {cardData.isOnEthereum ? (isOwned ? "Sell" : "Buy") : "Unlisted"}
              </div>
            </button>
          </Col>
        </Row>
      )}
    </Card>
    {/* BACK */}
    <Card 
      style={{
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.5)', 
        padding: '0%', 
        width: isMobile ? 'auto' : cardWidth, 
        height: isMobile ? 'auto' : cardHeight,
        transition: 'height 0.5s ease',
        transformOrigin: 'top'
      }}
      onMouseEnter={e => {
        e.currentTarget.style.height = `${cardHeight + 50}px`;
        e.currentTarget.style.marginBottom = '-50px';
      }}
      onMouseLeave={e => {
        e.currentTarget.style.height = `${cardHeight}px`;
        e.currentTarget.style.marginBottom = '0px';
      }}
      onClick={() => {initVanillaTilt();setFlipped(!flipped)}}
    >
      <Card.Body>
        <Card.Title>{cardData.title}</Card.Title>
        <table style={{fontSize: '0.9rem', width:"100%",borderRadius:"15%", textAlign:"center"}}>
        <thead>
        <tr>
          <th>
            {cardData.body}
          </th>
        </tr>
        </thead>
        <tbody>
          {cardData.skillsName.map((row, index) => (
            <tr key={index}>
              <td>{row}</td>
              <td>{cardData.skillsValue[index]}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </Card.Body>
    </Card>
    </ReactCardFlip>
  );
}

export default PlayerCardMarket;