// src/components/cardsGrid/PositionScore.js

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Rectangle from '../cardsGrid/Rectangle';

const PositionScore = () => (
  <Row className="justify-content-center" style={{ paddingBottom: '1%' }}>
    <div className='formation-legend box-shadow-simple-2'>
      <Row className="justify-content-center">
        <Col md="auto" xs="auto">  
          <div style={{
            fontFamily: '"Roboto", sans-serif',
            fontWeight: 'bold',
            color: 'black'
          }}>
            Position Score
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center" style={{ padding: '10px' }}>

        {/* Great 70+ */}
        <Col
          xs={12}
          md="auto"
          className="d-flex flex-column flex-md-row align-items-center mb-2 mb-md-0"
        >
          <div
            style={{
              display: 'inline-block',
              background: 'linear-gradient(62deg, #31AA10 0%, #52c234 100%)',
              borderRadius: '5px',
              padding: '3px',
              color: 'black',
              marginRight: '0.5rem' // optional spacing for larger screens
            }}
          >
            <Rectangle borderColor={"#fff"} />
          </div>
          <div
            style={{
              fontFamily: '"Roboto", sans-serif',
              fontStyle: 'italic',
              color: 'black'
            }}
          >
            Great 70+
          </div>
        </Col>

        {/* Ok 40+ */}
        <Col
          xs={12}
          md="auto"
          className="d-flex flex-column flex-md-row align-items-center mb-2 mb-md-0"
        >
          <div
            style={{
              display: 'inline-block',
              background: 'linear-gradient(to right, #FAEE46 0%, #F5E887 51%, #F5E887 100%)',
              borderRadius: '5px',
              padding: '3px',
              color: 'black',
              marginRight: '0.5rem'
            }}
          >
            <Rectangle borderColor={"#fff"} />
          </div>
          <div
            style={{
              fontFamily: '"Roboto", sans-serif',
              fontStyle: 'italic',
              color: 'black'
            }}
          >
            Ok 40+
          </div>
        </Col>

        {/* Poor 15+ */}
        <Col
          xs={12}
          md="auto"
          className="d-flex flex-column flex-md-row align-items-center"
        >
          <div
            style={{
              display: 'inline-block',
              background: 'linear-gradient(62deg, rgb(251, 171, 126) 0%, #ff6f69 100%)',
              borderRadius: '5px',
              padding: '3px',
              color: 'black',
              marginRight: '0.5rem'
            }}
          >
            <Rectangle borderColor={"#fff"} />
          </div>
          <div
            style={{
              fontFamily: '"Roboto", sans-serif',
              fontStyle: 'italic',
              color: 'black'
            }}
          >
            Poor 15+
          </div>
        </Col>

      </Row>
    </div>
  </Row>
);

export default PositionScore;