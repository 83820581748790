import React, { useCallback, useEffect, useState, useContext } from "react";
import { Link } from 'react-router-dom';
// Components
import { Col, Row, Spinner } from 'react-bootstrap';
import DownloadWalletModal from '../modal/DownloadWalletModal';
// login 
import * as login from '../../helpers/login';
// Misc
import LoginButton from './LoginButton';
import '../../styles/branding.scss';

function LoginBanner({ loginButtonFallback, startTimer, isMobile }) {
    const [signer, setSigner] = useState(undefined)
    const [accountsLoading, setAccountsLoading] = useState(false)
    const [accountCopied, setAccountCopied] = useState(false)
    const [timeRemaining, setTimeRemaining] = useState(5 * 60 + 30);
    const [openDownloadMetaMask, setOpenDownloadMetaMask] = useState(false)

    useEffect(() => {
        // console.log(startTimer)
        let intervalId;
        if (startTimer) {
            // Set up an interval that ticks every second
            intervalId = setInterval(() => {
                setTimeRemaining((time) => {
                    if (time >= 1) return time - 1;
                    // When countdown finishes, clear the interval and stop the timer
                    clearInterval(intervalId);
                    window.location.reload();
                    return 0;
                });
            }, 1000);
        }
        
        // Clean up the interval on unmount
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [startTimer]);



    const accountListenerFallback = useCallback(async (callbackData) => {
        // TO DO: fix this, only signer is in callbackData but loginMethod and web3 could be passed in too
        let loginData = prepLoginData(callbackData.signer, undefined, [], [], "", undefined);
        loginButtonFallback(loginData)
    }, []);

    const formatTime = () => {
        const minutes = Math.floor(timeRemaining / 60);
        const seconds = timeRemaining % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    async function handleLoginClick(userInfo){
        let loginMethod; let web3;
        if (signer == undefined && userInfo != undefined) {
            try {
                // console.log("userInfo:", userInfo);
                setSigner(userInfo.wallet);
                if (userInfo.verifier != undefined) {
                    loginMethod = "WEB3AUTH";
                    web3 = userInfo.provider;
                } else {
                    loginMethod = "WEB3";
                    web3 = await login.loadWeb3Provider();
                    await login.accountChangeListener(accountListenerFallback);
                }
                let loginData = prepLoginData(userInfo.wallet, undefined, [], [], loginMethod, web3);
                loginButtonFallback(loginData)
            } catch (e) {
                console.log("Error logging in:", e);
            }
        } else {
            console.log("sign out")
            let loginData = prepLoginData(undefined, undefined, [], "", [])
            loginButtonFallback(loginData)
            setSigner(undefined);
            // await signOut();
        }
    };

    return (
        <div style={{ paddingBottom: "3px" }}>
            {!isMobile ? (
                 <div className="banner-row">
                        <Col xs={2} >
                        </Col>
                        <Col xs={startTimer ? 3 : 7} >
                            {signer != undefined && (
                                <>
                                <div style={{paddingLeft: "5%"}}>
                                    <button className="button-formation" >
                                        <span className='table-title' style={{ color: "black", float: "centre" }}>
                                            Credits: 
                                        </span>
                                        <span className='sub-title-mobile' > 
                                            0
                                        </span>
                                    </button>
                                </div>
                                </>
                            )}
                        </Col>
                        {startTimer && (
                            <>
                                <Col xs={3}>
                                    <div style={{ fontStyle: "italic" }}>
                                        Setting the field. Your journey to league glory begins shortly...
                                    </div>
                                </Col>
                                <Col xs={2}>
                                    <div>
                                        <button className='button-1' >
                                            Countdown: {formatTime()}
                                        </button>
                                    </div>
                                </Col>
                            </>
                        )}
                        <Col xs={isMobile ? 6 : 3} style={!isMobile ? { paddingLeft: "2%" } : {}}>
                            <LoginButton 
                                isMobile={isMobile}
                                onLoginClick={handleLoginClick}
                            />
                        </Col>
                    </div>
                
            ) : (
                <div >
                    <div className="banner-row-mobile">
                        <Col xs={2} style={{ color: 'white', fontStyle: 'italic', position: "absolute", top: "2%", left: "25%" }}>
                            <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div className='sub-title-mobile' style={{ paddingLeft: "10%", fontSize: '20pt' }}>CHAINBALLERS</div>
                            </Link>
                        </Col>
                    </div>
                    <Row className="banner-row-mobile justify-content-center">
                        <Col xs={10}>
                            {signer == undefined && (
                                <>
                                    {accountsLoading ? (
                                        <div style={{ color: "white" }}>
                                            Loading your accounts...
                                        </div>
                                    ) : (
                                        <div style={{ color: 'white' }}>
                                            {/* Sign in to load your accounts. */}
                                        </div>
                                    )}
                                </>
                            )}
                        </Col>
                        <Col xs="auto">
                            <LoginButton 
                                onLoginClick={handleLoginClick} 
                                isMobile={isMobile}
                            />
                        </Col>
                    </Row>
                </div>
            )}
            <DownloadWalletModal
                show={openDownloadMetaMask}
                onHide={() => setOpenDownloadMetaMask(false)}
            />
        </div>
    );

    function handleCopyAccount() {
        setAccountCopied(true)
        setTimeout(() => {
            setAccountCopied(false);
        }, 2000);
    }

    function prepLoginData(signer, selectedSafe, ownedSafes, ownedSafesData, loginMethod, web3) {
        // console.log(signer,selectedSafe,ownedSafes,ownedSafesData,web3)
        let loginData = {}
        loginData.signer = signer;
        // loginData.signer ="0x7bF8ee10E37498b1338c64458ec3a546F38BC40D" ;
        loginData.selectedSafe = selectedSafe;
        loginData.ownedSafes = ownedSafes;
        loginData.ownedSafesData = ownedSafesData;
        loginData.loginMethod = loginMethod;
        if (web3 != undefined) {
            loginData.web3 = web3;
        } 
        return loginData;
    }
    
    function prepAccountsData(selectedSafe, nav, ownedSafes, ownedSafesData) {
        let accountsData = {}
        accountsData.selectedSafe = selectedSafe;
        accountsData.nav = nav;
        accountsData.ownedSafes = ownedSafes;
        accountsData.ownedSafesData = ownedSafesData;
        return accountsData;
    }
    
}

export default LoginBanner;