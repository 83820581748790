import React, { Component } from 'react';
import { Col, Container,Row, Spinner} from 'react-bootstrap';
// Components
import LeaguesGrid from '../components/leagues/LeaguesGrid';
import TeamListLoaded from '../components/nft/TeamListLoaded';
import SignInButton from '../components/SignInButton';
import TxModal from '../components/modal/TxModal';
import TxHandlingModal from '../components/modal/TxHandlingModal';
import NameChangeModal from '../components/modal/NameChangeModal';
import confetti from 'canvas-confetti';
import LeagueInfo from '../components/leagues/leagueInfo/LeagueInfo';
import YourTeam from '../components/yourTeam/YourTeam';
import LeagueSelection from '../components/leagues/leagueSelection/LeagueSelection';
// Contract for team creation abi
import INFTContract from '../contracts/INFTContract.json'
// Helpers
import * as api from '../helpers/api.js';
import * as coreWeb3 from '../helpers/coreWeb3.js';
import * as utils from '../helpers/utils.js';
import * as players from '../helpers/players.js';
import { transformToBNotation } from '../helpers/utils.js';

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formationArray: [4,4,2],
      signedMessage: '',
      couponLoading: false,
      txStatus: {currentStep: 0, totalSteps: 0},
    }
    document.body.style = 'background: #ffffff;';
    this.sendCustomTeamTx = this.sendCustomTeamTx.bind(this);
    this.createTeam = this.createTeam.bind(this);
    this.createTeamCouponCode = this.createTeamCouponCode.bind(this);
    this.handleTeamNameClick = this.handleTeamNameClick.bind(this);
  }
  
  async componentDidMount(){
    await this.checkSize();
    await this.props.dashboardCallback();
    if (this.props.account !== undefined && this.props.ownedTeamId === undefined){
      this.setState({loadingGasCost: true})
      await this.estimateGasCost()
      this.setState({loadingGasCost: false})
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.account !== prevProps.account) {
      this.setState({loadingGasCost: true})
      await this.estimateGasCost();
      this.setState({loadingGasCost: false})
    }

    if (this.props.ownedTeamLeague !== prevProps.ownedTeamLeague) {
      if (this.props.ownedTeamLeague !== undefined && this.props.ownedTeamLeague > 0){
        // UPDATE CACHE

        api.addLeagueTeams(this.props.ownedTeamLeague, this.props.ownedTeamChainId);
        api.updateAllLeagueTeams(this.props.ownedTeamLeague, this.props.ownedTeamChainId);
        this.props.getLeagueData(this.props.ownedTeamLeague, this.props.ownedTeamChainId);
      }
    }
  }

  async checkSize() {
    this.setState({
        isMobile : window.innerWidth < 1000
    });
  }

  handleShowLeaguesClick(){
    if (this.state.showLeagues){
      this.setState({
        showLeagues : false
      })
    } else {
      this.setState({
        showLeagues : true
      })
    }
  }

  async handleShowLeagueRankingClick(){
    this.setState({
      loadingLeagueRanking : true
    })
    await this.props.getLeagueData(this.props.ownedTeamLeague)
    this.setState({
        showLeagueRanking : true,
        loadingLeagueRanking : false,
    })
  }

  
  handleSetFormationClick() {}

  setNameChangeModal(value){
    this.setState({
      showNameChangeModal: value
    })
  }

  makeConfetti = () => {
    confetti({
      zIndex: 999,
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  }

  async createTeam(teamChainId){
    // console.log(this.props)
    // console.log(this.props.loginMethod)
    if (this.props.loginMethod === 'WEB3'){
      await this.createTeamWeb3(teamChainId);
    } else if (this.props.loginMethod === 'WEB3AUTH'){
      await this.createTeamWeb3Auth(teamChainId);
    }
  }

  async createTeamWeb3Auth(){
    try {
      await this.props.payWithStripe();
      // Further logic to create the team after payment
    } catch (error) {
        console.error('Error with stripe:', error);
    }
  }

  async createTeamWeb3(teamChainId){
    let web3 = this.props.web3;
    var network = await web3.eth.net.getId();

    if (network !== teamChainId){
      try{
        await coreWeb3.switchToChain(web3, teamChainId);
      } catch (error) {
        console.log(error)
      }
    }

    network = await web3.eth.net.getId();
    this.setState({
      network: network
    })


    try{
      let nftContract = new web3.eth.Contract(INFTContract.abi, this.props.CBAddressL2);
      // FEE ESTIMATION & WARNING: is fee estimation even needed?
      // const balance = await web3.eth.getBalance(account);
      // const balanceInEther = web3.utils.fromWei(balance, 'ether'); 
      // const gasEstimate = await nftContract.methods.createTeam().estimateGas({ from: account });
      // const gasPrice = await web3.eth.getGasPrice();
      // console.log(gasEstimate, gasPrice)
      // const maxTransactionFee = gasEstimate * gasPrice;
      // const maxTransactionFeeInEther = web3.utils.fromWei(maxTransactionFee.toString(), 'ether');
      // console.log(maxTransactionFee,maxTransactionFeeInEther)
      // let maxAffordableFee = this.findMaxGasFee(gasEstimate,balanceInEther)
      // console.log(maxAffordableFee)
      // let maxAffordableTxFee = web3.utils.fromWei((gasEstimate * maxAffordableFee).toString(), 'ether');
      // let minReasonableTxFee = web3.utils.fromWei((gasEstimate * 10000000000).toString(), 'ether');
      // if (parseFloat(balanceInEther) < parseFloat(maxTransactionFeeInEther)) {
      //     console.log("Insufficient funds", balanceInEther, maxTransactionFeeInEther);
      //     this.setState({
      //       accountEthBalance: balanceInEther,
      //       txPrice: maxTransactionFeeInEther,
      //       maxTransactionFeeInEther: maxTransactionFeeInEther,
      //       gasEstimate: gasEstimate,
      //       gasPrice: gasPrice,
      //       maxAffordableFee: maxAffordableFee,
      //       maxAffordableTxFee: maxAffordableTxFee,
      //       minReasonableTxFee: minReasonableTxFee,
      //       showTxHandlingModal: true,
      //     })
      // } else {
        this.setState({
          showTxModal: true
        })  
        await this.sendCreateTeamTx(nftContract, null, null, teamChainId)
      // }
    } catch (error) {
        // console.log(error)
    }

    this.handleCloseTxModal();
  }

  onLeagueSelect = async (leagueId) => {
    await this.props.onLeagueSelect(leagueId);
  }

  async sendCreateTeamTx(nftContract, maxPriorityFeePerGas,maxFeePerGas, teamChainId){
    console.log("Creating team on chain", teamChainId)
    await nftContract.methods.createTeamB().send({ 
      from: this.props.account,
      maxPriorityFeePerGas: maxPriorityFeePerGas, 
      maxFeePerGas: maxFeePerGas
    })
    .once('transactionHash', txHash => {
      let txStatus = this.state.txStatus;
      txStatus.currentStep = 1;
      this.setState({
        txStatus: txStatus
      })
      setTimeout(() => {
        console.log(txHash);
      }, 500);
      })
    .on('receipt', txReceipt =>
    {
      let txStatus = this.state.txStatus;
      txStatus.currentStep = 2;
      this.setState({
        txStatus: txStatus
      })
      console.log(txReceipt)
      if (teamChainId === 1){
        this.props.loadTeamOnL1(txReceipt);
        this.makeConfetti();
        this.props.timerSetter();
      } else {
        this.props.getOwnedTeam();
      }
    }
    )
  }

  async estimateGasCost() {
    let totalCostUSD = 0;
    let costData = await api.getNewTeamCost();
    if (costData !== 0){
      totalCostUSD = costData.cost.totalCostUSD;
    } else {
      totalCostUSD = "N/A";
    }
    this.setState({
      totalCostUSD : totalCostUSD
    })
  }

  async createTeamCouponCode(){
    let web3 = this.props.web3;
    let account = this.props.account;
    const network = await web3.eth.net.getId();

    if (network !== 8453){
      console.log("switching to chain")
      await coreWeb3.switchToChain(web3, "0x2105");
    }

    let signedMessage = await this.signTypedData(web3,account)
    this.setState({
      signedMessage: signedMessage
    })
    if (signedMessage == undefined){
        throw new Error("No signed message")
    }
    api.sendCouponCode(account,signedMessage)
    // check if coupon code is valid
    let couponStatus = await api.isCouponSponsored(account,signedMessage);
    // Disabled for mainnet
    // if (couponStatus){
    //   await api.createTeamFromCoupon(account,signedMessage)
    // }
  }

  handleCloseTxModal(){
    this.setState({
      showTxModal: false
    })
  }

  handleCloseTxHandlingModal(){
    this.setState({
      showTxHandlingModal: false
    })
  }

  async sendCustomTeamTx(inputETHCustomFee){
    let nftContract = new this.props.web3.eth.Contract(INFTContract.abi, this.props.CBAddressL1);
    this.setState({
      showTxHandlingModal: false,
      showTxModal: true
    })  
    let maxFeePerGas = null;
    if (inputETHCustomFee !== undefined){
      let inputCustomFee= this.props.web3.utils.toWei(inputETHCustomFee, 'ether') / this.state.gasEstimate;
      // console.log(Math.round(inputCustomFee))
      await this.sendCreateTeamTx(nftContract, null, Math.round(inputCustomFee))
    } else {
      maxFeePerGas = this.state.maxAffordableFee
      // console.log(maxFeePerGas)
      if (maxFeePerGas >= 10000000000){ //12gwei decent minimum
        await this.sendCreateTeamTx(nftContract, null, maxFeePerGas)
      } else {
        this.setState({
          showTxHandlingModal: false,
          showTxModal: false
        })
        window.alert("Your balance is too low. Please top up wallet to continue.")
      }
    }
  }

  findMaxGasFee(inputEstimate, accountBalance){
    var gasEstimate; var accountEthBalance;
    if (inputEstimate !== undefined){
      gasEstimate = inputEstimate;
    } else {
      gasEstimate = this.state.gasEstimate;
    }
    if (accountBalance !== undefined){
      accountEthBalance = accountBalance;
    } else {
      accountEthBalance = this.state.accountEthBalance;
    }
    // console.log(gasEstimate)
    // try with 320000000000000 in maxFeePerGas, if enough decrease 40000000000000 until 0
    let web3 = this.props.web3;
    let maxFeePerGas = 32000000000;
    for (let i = 0; i < 8; i++){
      // console.log(this.state.gasEstimate,maxFeePerGas)
      const maxTransactionFee = gasEstimate * maxFeePerGas;
      // console.log(maxTransactionFee)
      const maxTransactionFeeInEther = web3.utils.fromWei(maxTransactionFee.toString(), 'ether');
      if (parseFloat(accountEthBalance) > parseFloat(maxTransactionFeeInEther)) {
        return maxFeePerGas - 5000000000
      } else {
        maxFeePerGas = maxFeePerGas - 4000000000;
      }
    }
    return 0;
  }

  async signTypedData(web3,fromAddress){

    // Defining the message signing data content.
    const message= {
      to: fromAddress,
      players: 11,
      deadline: 1700000000, // 14th November 2023
    }

    const typedData = JSON.stringify({
      types: {
        EIP712Domain: [
          { name: "chainId", type: "uint256" },
          { name: "verifyingContract", type: "address" },
        ],
        CreateTx: [
          { name: "to", type: "address" },
          { name: "players", type: "uint256"},
          { name: "deadline", type: "uint256" }
        ]
      },
      primaryType: "CreateTx",
      domain: {
        chainId: 8453,
        verifyingContract: this.props.CBAddressL2
      },
      message: message,
    });

    // console.log(typedData)

    const params = [fromAddress, typedData];

    const method = "eth_signTypedData_v4";
    // console.log(params)

    var signedMessage;
    if (!utils.isWeb3Wallet(web3)){
      return await this.sendDirectSigRequest(web3,method, params, fromAddress);
    } else {
        signedMessage = await this.sendAsyncSigRequest(web3,method,params,fromAddress).then((signedMessage) => {
        console.log('TYPED SIGNED: ' + JSON.stringify(signedMessage));
        return JSON.stringify(signedMessage).slice(1,-1);
      })
      .catch((error) => {
        console.error('ERROR', error);
      });
    }
    return signedMessage;
  }

  sendAsyncSigRequest(web3,method, params, fromAddress) {
    return new Promise((resolve, reject) => {
      web3.currentProvider.sendAsync(
        {
          method,
          params,
          from: web3.utils.toChecksumAddress(fromAddress),
        },
        function (err, result) {
          if (err) {
            reject(err);
          } else if (result.error) {
            reject(new Error(result.error.message));
          } else {
            resolve(result.result);
          }
        }
      );
    });
  }

  async sendDirectSigRequest(web3,method, params, fromAddress) {
    console.log(params, fromAddress)
    const signedMessage = await web3.currentProvider.sendAsync(
      {
        from: web3.utils.toChecksumAddress(fromAddress),
        method,
        params,
      }
    );
    return signedMessage;
  }

  async handleTeamNameClick(){
    this.setNameChangeModal(true)
  }

  getGames = async (teamLeague) => {
    const availableGames = this.props.availableGames || await this.props.getAvailableGames(teamLeague);
    console.log("Available games: ", availableGames);
    
    return availableGames.map(game => {
      let imageUrlHome = players.uriToImage(game.teamUriHome);
      let imageUrlAway = players.uriToImage(game.teamUriAway);
      
      let teamNameHome = game.teamNameHome || (game.home_team_id.toString().startsWith('8453') ? 
        `Team #${transformToBNotation(game.home_team_id.toString())}` : 
        `Team #${game.home_team_id}`);
      let teamNameAway = game.teamNameAway || (game.away_team_id.toString().startsWith('8453') ? 
        `Team #${transformToBNotation(game.away_team_id.toString())}` : 
        `Team #${game.away_team_id}`);

      return {
        imageUrlHome,
        imageUrlAway,
        teamNameHome,
        teamNameAway,
        buttonText: "Play",
        gameStart: game.game_start_time,
        gameId: game.game_id
      };
    });
  }

  render(){
    // console.log("ownedTeamId: ", this.props.ownedTeamId);
    return (
      <>
        {this.props.account !== undefined ? (
          <div>
          <Container className="justify-content-center" style={ !this.props.isMobile ? { paddingTop: "2%"} : {paddingTop: "5%", paddingLeft: "5%"}}>
            {this.props.ownedTeamId > 0 ? (
              <YourTeam
                ownedTeamId={this.props.ownedTeamId}
                ownedTeamLeague={this.props.ownedTeamLeague}
                createTeam={this.createTeam}
                onLeagueSelectFallback={this.props.onLeagueSelectFallback}
                isMobile={this.props.isMobile}
                web3={this.props.web3}
                totalCostUSD={this.state.totalCostUSD}
                loadingGasCost={this.state.loadingGasCost}
                createTeamCouponCode={this.createTeamCouponCode}
                couponLoading={this.state.couponLoading}
                signedMessage={this.state.signedMessage}
                ownedTeamName={this.props.ownedTeamName}
                handleTeamNameClick={this.handleTeamNameClick}
                account={this.props.account}
                CBAddressL1={this.props.CBAddressL1}
                CBAddressL2={this.props.CBAddressL2}
                ownedPlayers={this.props.ownedPlayers}
                loadingCallback={this.loadingCallback}
                handlePlayerSelect={this.props.handlePlayerSelect}
                handleSetFormationClick={this.handleSetFormationClick}
                network={this.state.network}
              />
            ) : (
              this.props.ownedTeamId === 0 ? (
                <LeagueSelection
                  createTeam={this.createTeam}
                  onLeagueSelect={this.onLeagueSelect}
                  isMobile={this.props.isMobile}
                />
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                  <h1><Spinner animation="border" variant="dark" /></h1>
                </div>
              )
            )}
            {/* LEAGUES SECTION IF IN LEAGUE */}
            {this.props.ownedTeamLeague > 0 && (
            <>
              <LeagueInfo
                leaguePrize={this.props.leaguePrize}
                teamsData={this.props.teamsData}
                getLeagueData={this.props.getLeagueData}
                isMobile={this.props.isMobile}
              />
              <Col style={{display: 'flex',justifyContent: 'center' }}>
                  {this.state.showLeagues ? (
                    <button className="button-1" onClick={() => this.handleShowLeaguesClick()} > 
                      Hide Leagues
                    </button>
                  ): (
                    <button className="gradient-button comic-neue" onClick={() => this.handleShowLeaguesClick()} > 
                      <div className="sub-title-mobile">
                        All Divisions
                      </div>
                    </button>
                  )}
              </Col>
              {this.state.showLeagues && (
                <>
                <Row className="justify-content-center" style={{ paddingTop:'2%', paddingBottom: '2%'}}>
                  <span className='table-title'>
                    Leagues
                  </span>
                </Row>
                <Row className="justify-content-center" style={this.props.isMobile ? {paddingLeft:"5%"}:{paddingBottom: '5%'}}>
                  <div style={{ border: '1px solid #ccc', borderRadius: '16px', margin: '0pc  0', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '1200px', width: '100%' }}>
                    <LeaguesGrid
                      getLeagueData = {this.props.getLeagueDataStateless}
                      teamsData = {this.props.teamsData}
                      getNumberOfLeagues = {this.props.getNumberOfLeagues}
                      isMobile={this.props.isMobile}
                    />
                  </div>
                </Row>
                </>
              )}
            </>
            )}
          </Container>
          </div>
        ):(
          <div>
          {/* IF NOT SIGNED IN */}
          <div style={{paddingTop: "0%"}}>
            <Container style={ !this.props.isMobile ? {paddingLeft: "2%",minWidth: '800px', maxWidth: '900px'} : {paddingTop: "0%"}}>
              <Row className="justify-content-center" style={{paddingTop:'10%', paddingBottom: "2%"}}>
                <Col md="auto" xs="auto">
                  <span className="sub-title" style={!this.props.isMobile ? {fontSize: "28pt"} : {fontSize: "20pt"}}>
                    Total League Prizes
                  </span>
                </Col>
              </Row>
              <Row className="justify-content-center"style={!this.props.isMobile ? {paddingBottom: "3%"} : {paddingBottom: "6%"}}>
                <Col md="auto" xs="auto">
                  <span className="sub-title">
                    1,000 $
                  </span>
                </Col>
              </Row>
              <Row className="justify-content-center" style={!this.props.isMobile ? {paddingBottom: "1%"} : {paddingBottom: "3%"}}>
                <Col md="auto" xs="auto">
                  <span className='sub-title' style={!this.props.isMobile ? {fontSize: "28pt"} : {fontSize: "20pt"}}>
                    Current Champions
                  </span>
                </Col>
              </Row>
              <TeamListLoaded
                teamData = {this.state.sampleTeam}
                isMobile = {this.props.isMobile}
              />
              <Row className="justify-content-center" style={{paddingBottom:'10%'}}>
                <Col md="auto" xs="auto">
                  <SignInButton
                    customText={'Join Now'}
                    isMobile={this.props.isMobile}
                    loginButtonFallback={this.props.loginButtonFallback}
                  />
                </Col>
              </Row>
            </Container>
          </div>
          </div>
        )}
      <TxModal
        show={this.state.showTxModal}
        onHide={() => this.handleCloseTxModal()}
        txHash={this.state.txHash}
        txStatus={this.state.txStatus}
        isMobile={this.props.isMobile}
      />
      <TxHandlingModal
        show={this.state.showTxHandlingModal}
        onHide={() => this.handleCloseTxHandlingModal()}
        account={this.props.account}
        txPrice={this.state.txPrice}
        accountEthBalance={this.state.accountEthBalance}
        sendCustomTeamTx={this.sendCustomTeamTx}
        minReasonableTxFee={this.state.minReasonableTxFee}
        maxAffordableFee={this.state.maxAffordableFee}
        maxAffordableTxFee={this.state.maxAffordableTxFee}
        isMobile={this.props.isMobile}
      />
      <NameChangeModal
        show={this.state.showNameChangeModal}
        onHide={() => this.setNameChangeModal(false)}
        setTeamName={this.props.setTeamName}
        CBAddress={this.props.CBAddressL2}
        isMobile={this.props.isMobile}
      />
      </>
    )};
}

export default Dashboard;

