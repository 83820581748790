import React, { useState, useEffect } from 'react';
import { useSprings, animated, to as interpolate } from '@react-spring/web';
import { useDrag } from 'react-use-gesture';
import styles from './Deck.module.css';
import SkillBar from '../skillBar/SkillBar';

const defaultCards = [
  { image: '/itineraries/itinerary6.png', title: 'Baller #1' },
  { image: '/itineraries/itinerary5.png', title: 'Baller #2' },
  { image: '/itineraries/itinerary4.png', title: 'Baller #3' },
  { image: '/itineraries/itinerary3.png', title: 'Baller #4' },
  { image: '/itineraries/itinerary2.png', title: 'Baller #5' },
  { image: '/itineraries/itinerary1.png', title: 'Baller #6' },
];

const to = (i, total) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: i === total - 1 ? 0 : (-10 + Math.random() * 20),
  delay: i * 100,
});

const from = (_i, total) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });
const trans = (r, s) => `perspective(1500px) rotateX(30deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`;

const getLevelupChanceColor = (chance) => {
  const percentage = parseInt(chance);
  if (percentage >= 70) return '#4CAF50';  // Green for high chance
  if (percentage >= 50) return '#2c83e1b3';  // Orange for medium chance
  if (percentage >= 20) return '#FFA726';  // Orange for medium chance
  return '#F44336';  // Red for low chance
};

const calculateLevelupChance = (card) => {
  if (!card.skillsValue || card.skillsValue.length === 0) return null;
  const numSkills = card.skillsValue.length;
  const sumSkills = card.skillsValue.reduce((sum, value) => sum + value, 0);
  return Math.round(((100 * numSkills) - sumSkills) / numSkills) + '%';
};

// Add arrow icon (you can replace with your preferred arrow image/icon)
const UpArrow = () => (
  <span style={{ 
    color: '#4CAF50', 
    marginLeft: '5px',
    fontSize: '1.2em',
    fontWeight: 'bold'
  }}>
    ↑
  </span>
);

export function Deck({ cards = defaultCards, onSwipeRight, onSwipeLeft, visible = true, showSuccessfulTrainees = false }) {
  const [gone] = useState(() => new Set());
  const [props, api] = useSprings(cards.length, (i) => ({
    ...to(i, cards.length),
    from: from(i, cards.length),
  }));

  const bind = useDrag(({ args: [index], down, movement: [mx], direction: [xDir], velocity }) => {
    const trigger = velocity > 0.2;
    const dir = xDir < 0 ? -1 : 1;
    
    if (!down && trigger) {
      gone.add(index);
      if (dir === 1 && onSwipeRight) {
        onSwipeRight(cards[index]);
      } else if (dir === -1 && onSwipeLeft) {
        onSwipeLeft(cards[index]);
      }
    }

    api.start((i) => {
      if (index !== i) return;
      const isGone = gone.has(index);
      const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0;
      const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0);
      const scale = down ? 1.1 : 1;
      return {
        x,
        rot,
        scale,
        delay: undefined,
        config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
      };
    });
    if (!down && gone.size === cards.length)
      setTimeout(() => {
        gone.clear();
        api.start((i) => to(i));
      }, 600);
  });

  return (
    <>
      {visible && props.map(({ x, y, rot, scale }, i) => (
        <animated.div className={styles.deck} key={i} style={{ x, y }}>
          <animated.div
            {...bind(i)}
            style={{
              transform: interpolate([rot, scale], trans),
              border: showSuccessfulTrainees ? '3px solid #4CAF50' : 'none',
              boxShadow: showSuccessfulTrainees ? '0 0 15px rgba(76, 175, 80, 0.5)' : 'none',
            }}
            className={styles.cardContent}
            aria-label={cards[i].title}
            role="button"
            tabIndex={0}
          >
            <div 
              className={styles.cardImage} 
              style={{ backgroundImage: `url(${cards[i].image})` }}
            >
              {calculateLevelupChance(cards[i]) && (
                <div 
                  className={styles.levelupChance}
                  style={{ 
                    backgroundColor: getLevelupChanceColor(calculateLevelupChance(cards[i])) 
                  }}
                >
                  Chance {calculateLevelupChance(cards[i])}
                </div>
              )}
            </div>
            {cards[i].skillsName && (
              <div className={styles.attributes}>
                <table style={{ width: "100%", textAlign: "left" }}>
                  <tbody style={{ lineHeight: "1.2" }}>
                    {cards[i].skillsName
                      .map((skill, index) => ({skill, index}))
                      .filter(({skill}) => skill.toLowerCase() !== 'learning rate')
                      .map(({skill, index}) => (
                      <tr key={index}>
                        <td>
                          {skill}
                        </td>
                        <td style={{ paddingLeft: "2%" }}>
                          <div style={{ width: "100px" }}>
                            <SkillBar skillValue={cards[i].skillsValue[index]} />
                          </div>
                        </td>
                        <td style={{ paddingLeft: "10%" }}>
                          <span className="sub-title-mobile">
                            {cards[i].skillsValue[index]}
                            {showSuccessfulTrainees && 
                             cards[i].improvedSkill === skill && 
                             <UpArrow />}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <div className={styles.title}>{cards[i].title}</div>
          </animated.div>
        </animated.div>
      ))}
    </>
  );
}

export default Deck;