import React, { useState, useEffect, useRef } from 'react';
import { Table, Container } from 'react-bootstrap';
import { transformToBNotation } from '../../helpers/utils';

const LeagueTable = (props) => {
  const [teamsData, setTeamsData] = useState([[]]);

  useEffect(() => {
    const fetchLeagueData = async () => {
      if (props.leagueId > 0) {
        const leagueData = await props.getLeagueData(props.leagueId);
        setTeamsData(leagueData);
      }
    };
    fetchLeagueData();
  }, [props.leagueId]);

  useEffect(() => {
    if (props.teamsData !== undefined) {
      setTeamsData(props.teamsData);
    }
  }, [props.teamsData]);


  const tableRow = (data) => {
    if (data !== undefined) {
      return (
        <>
          {data.map((item, i) => (
            <tr key={i}>
              <td>{item[0]}</td>
              <td>{item[1] !== undefined && item[1].startsWith('8453') ? transformToBNotation(item[1]) : item[1]}</td>
              <td>{item[2] !== undefined && item[2] !== '' ? item[2] : 'Unnamed Team'}</td>
              <td>{item[3]}</td>
              <td>{item[4]}</td>
            </tr>
          ))}
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Container>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th># Rank</th>
              <th>Team Id</th>
              <th>Team Name</th>
              <th>Points</th>
              <th>Goals</th>
              {/* <th>Username</th> */}
            </tr>
          </thead>
          <tbody>
            {tableRow(teamsData)}
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default LeagueTable;