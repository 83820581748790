import React, { useState, useEffect } from 'react';
import { BsFillStopCircleFill } from "react-icons/bs";
import { Row, Col, Container } from 'react-bootstrap';
import { svgTextToImage, replaceSvgBackgroundColorWithTransparencyLong } from '../../../helpers/players.js';
import './style/LeagueSelectionCard.css';

function OptimisticLeague({ createTeam, onLeagueSelect, isSelected, chainId }) {
  const [svgImage, setSvgImage] = useState(null);
  const [showHiddenText, setShowHiddenText] = useState(false);


  useEffect(() => {
    async function loadSvgAsText() {
      try {
        const response = await fetch('./images/faces/op-league-face.svg');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const svgText = await response.text();
        return svgText;
      } catch (error) {
        console.error('Error fetching the SVG:', error);
      }
    }

    loadSvgAsText().then(svgText => {
      const modifiedSvgText = replaceSvgBackgroundColorWithTransparencyLong(svgText, "#ffffff");
      const imageElement = svgTextToImage(modifiedSvgText);
      setSvgImage(imageElement); // Update state with the Image element
    });
  }, []);

  const fixedButtonRowStyle = {
    position: 'absolute',
    bottom: '5%',
    width: '100%',
    textAlign: 'center',
    zIndex: 3,
  };

  const containerStyle = {
    position: 'relative',
    height: '100%',
    paddingTop: '5%',
  };

  const handleCardClick = (e) => {
    if (e.target.closest('button')) return;
    onLeagueSelect();
  };

  const handleButtonClick = (e) => {
    e.stopPropagation();
    createTeam(chainId);
  };

  return (
    <div className={`first ${isSelected ? 'card-selected' : ''}`} onClick={handleCardClick}>
      <div className="base">
        <div className="gradient-overlay"></div>
        <img src="./images/optimistic-league.png" alt="Optimistic League" className="image-style" />
        <Container
          style={containerStyle}
          onMouseEnter={() => setShowHiddenText(true)}
          onMouseLeave={() => setShowHiddenText(false)}
        >
          <Row className="justify-content-center">
            <Col className='text-center z-index-3'>
              <div className='sub-title' style={{ lineHeight: "1.4" }}>Optimistic</div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className='text-center z-index-3'>
              <div className='sub-title' style={{ fontSize: "28pt", lineHeight: "1.2" }}>League</div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className='text-center z-index-3'>
              <div className='sub-title-style sub-title'>
                powered by
                <img src='https://l2beat.com/icons/optimism.png'
                  style={{ width: '30px', height: '30px', marginLeft: '20px', marginRight: '5px', marginTop: '5px'}} alt='optimism_logo' />
              </div>
            </Col>
          </Row>

          {!showHiddenText ? (
            <Row className="justify-content-center">
              <Col className='text-center z-index-3'>
                <img className="img-style" src={svgImage} alt="Optimistic League Face" />
              </Col>
            </Row>
          ) : (
            <>
              <Row className="justify-content-center" style={{ paddingTop: "4%" }}>
                <Col className='text-center z-index-3'>
                  <div className='hidden-text-style sub-title hidden-on-load'>
                    Number of Teams
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className='text-center z-index-3'>
                  <div className='large-hidden-text-style sub-title hidden-on-load'>
                    0
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className='text-center z-index-3'>
                  <div className='hidden-text-style sub-title hidden-on-load'>
                    Total Value
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className='text-center z-index-3'>
                  <div className='large-hidden-text-style sub-title hidden-on-load'>
                    $0
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className='text-center z-index-3'>
                  <div className='hidden-text-style sub-title hidden-on-load'>
                    Average Team Cost
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className='text-center z-index-3'>
                  <div className='large-hidden-text-style sub-title hidden-on-load'>
                    $0
                  </div>
                </Col>
              </Row>
            </>
          )}

          <Row style={fixedButtonRowStyle}>
            <Col>
              <button className={`button-style-1 unavailable`} >
                Create Team
                <BsFillStopCircleFill style={{ color: 'black', marginLeft: '5px' }} />
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default OptimisticLeague;
