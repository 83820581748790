import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { generateDate } from '../../helpers/utils';
const GameCard = ({ slide, isMobile, onPlayGame, index, buttonStyle = { className: "gradient-button" } }) => {
  const defaultButtonStyle = {
    width: "150px", 
    marginBottom: "0%"
  };

  return (
    <Row 
      className="box-shadow-simple" 
      style={{
        paddingTop: "2%", 
        backgroundColor: '#fff', 
        width: "80%", 
        minWidth: isMobile ? "300px" : "600px", 
        margin: "auto", 
        marginBottom: "2%", 
        borderRadius: "10px"
      }}
    >
      {!isMobile && (
        <Col md={3} style={{display: 'flex', flexDirection: 'column-reverse', justifyContent: 'flex-start', alignItems: 'center'}}>
          <img src={slide.imageUrlHome} height={"125px"}/>
        </Col>
      )}
      <Col style={{paddingBottom:"4%"}}>
        <Row className='justify-content-center'>
          <Col md="auto" style={{display: "flex", justifyContent: isMobile ? "center" : "flex-start"}}>
            <p style={{fontSize: "0.9rem"}}>{generateDate(slide.gameStart)}</p>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          {isMobile && (
            <Row className='justify-content-center'>
              <Col md={6} xs={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img src={slide.imageUrlHome} height={"125px"}/>
              </Col>
              <Col md={6} xs={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img src={slide.imageUrlAway} height={"125px"}/>
              </Col>
            </Row>
          )}
          {(slide.teamNameHome && slide.teamNameAway) ? (
            <>
              <Col md={isMobile ? 4 : 5} xs={5} style={{display: "flex", justifyContent: "center", alignItems: 'center'}}>
                <p>{slide.teamNameHome}</p>
              </Col>
              <Col md={isMobile ? 4 : 2} xs={1} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p>vs</p>
              </Col>
              <Col md={isMobile ? 4 : 5} xs={5} style={{display: "flex", justifyContent: "center", alignItems: 'center'}}>
                <p>{slide.teamNameAway}</p>
              </Col>
            </>
          ) : (
            <>
              <Col md={isMobile ? 12 : 5} style={{display: "flex", justifyContent: "center", alignItems: 'center'}}>
                <button 
                //   className={buttonStyle.className || "gradient-button"}
                  style={{ ...defaultButtonStyle, ...buttonStyle.style }}
                  onClick={() => onPlayGame(slide.gameId, index)}
                >
                  <div className="sub-title-mobile">
                    {slide.buttonText}
                  </div>
                </button>
              </Col>
              <Col md={isMobile ? 12 : 2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p>vs</p>
              </Col>
              <Col md={isMobile ? 12 : 5} style={{display: "flex", justifyContent: "center", alignItems: 'center'}}>
                <button 
                //   className={buttonStyle.className || "gradient-button"}
                  style={{ ...defaultButtonStyle, ...buttonStyle.style }}
                  onClick={() => onPlayGame(slide.gameId, index)}
                >
                  <div className="sub-title-mobile">
                    {slide.buttonText}
                  </div>
                </button>
              </Col>
            </>
          )}
        </Row>
        <Row className='justify-content-center'>
          <div className="sub-title-mobile" style={{fontStyle: 'italic'}}>
            Teams Ready!
          </div>
        </Row>
        <Row className='justify-content-center'>
          <button 
            className={buttonStyle.className || "gradient-button"}
            style={{ ...defaultButtonStyle, ...buttonStyle.style }} 
            onClick={() => onPlayGame(slide.gameId, index)}
          >
            <div className="sub-title-mobile">
              {slide.buttonText}
            </div>
          </button>
        </Row>
      </Col>
      {!isMobile && (
        <Col md={3} style={{display: 'flex', flexDirection: 'column-reverse', justifyContent: 'flex-start', alignItems: 'center'}}>
          <img src={slide.imageUrlAway} height={"125px"}/>
        </Col>
      )}
    </Row>
  );
};

export default GameCard;
