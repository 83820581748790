import { useEffect, useRef } from 'react';

const useCountdown = (timestamp) => {
  const spanRef = useRef(null);

  useEffect(() => {
    if (!spanRef.current || !timestamp) return;

    const updateCountdown = () => {
      const now = Math.floor(Date.now() / 1000);
      const timeLeft = Math.max(0, timestamp - now);
      if (spanRef.current) {
        spanRef.current.textContent = timeLeft;
      }
    };

    updateCountdown();
    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, [timestamp]);

  return spanRef;
};

export default useCountdown; 