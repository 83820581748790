import React from 'react';
import { Radar } from 'react-chartjs-2';
import 'chart.js/auto';

const RoseChart = ({
    playersData, 
    showLabels = true, 
    showLegend = true, 
    customColors = null
}) => {
    const defaultBackgroundColors = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
    ];
    const defaultBorderColors = [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
    ];

    const backgroundColors = customColors?.backgroundColors || defaultBackgroundColors;
    const borderColors = customColors?.borderColors || defaultBorderColors;

    var data = {}
    if (playersData && playersData.length > 0) {
        data = {
            labels: playersData[0].skillsName,
            datasets: playersData
            .filter(player => player !== undefined)
            .map((player, index) => ({
                label: player.title, 
                data: player.skillsValue,
                backgroundColor: backgroundColors[index % backgroundColors.length], 
                borderColor: borderColors[index % borderColors.length], 
                borderWidth: 1
            }))
        };
    }
    const options = {
        scales: {
            r: {
                angleLines: {
                    display: true
                },
                pointLabels: {
                    display: showLabels
                },
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                    display: false
                }
            }
        },
        plugins: {
            legend: {
                display: showLegend
            }
        }
    };
    
    return (
        <Radar data={data} options={options} />
    );
};

export default RoseChart;
