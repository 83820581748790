import React, { useState } from 'react';
import { Container, Dropdown, Col, Row } from 'react-bootstrap';
import { Notification } from '../notification/Notification';

const FormationMenu = ({ 
  selectedFormation, 
  formationTypes, 
  onFormationSelect, 
  onSaveFormation, 
  onSaveDefaultFormation,
  nextGameId,
  isMobile 
}) => {
  // Add state for notifications
  const [notifications, setNotifications] = useState([]);
  let notificationId = 0;

  const handleSaveDefault = async () => {
    let message = await onSaveDefaultFormation();
    setNotifications(prev => [...prev, {
      id: notificationId++,
      message: message
    }]);
  };

  const handleSaveFormation = async () => {
    try {
      let message = await onSaveFormation();
      setNotifications(prev => [...prev, {
        id: notificationId++,
        message: message
      }]);
    } catch (error) {
      setNotifications(prev => [...prev, {
        id: notificationId++,
        message: "Failed to save formation."
      }]);
    }
  };

  const removeNotification = (id) => {
    setNotifications(prev => prev.filter(notification => notification.id !== id));
  };

  if (!isMobile) {
    return (
      <>
        <Container className="box-shadow-simple-2 align-items-center justify-content-center d-flex" 
          style={{backgroundColor: '#fff', minWidth: '100%', width: '100%', minHeight: "80px"}}>
          <Row className="justify-content-center" style={{ paddingBottom: '1%', paddingTop: '1%', maxWidth: "900px", zIndex: 2 }}>
            <Col md={4} className="d-flex justify-content-center align-items-center">
              <Dropdown className="gradient-button-no-margin" style={{ minHeight: "59px", minWidth: "220px", width: "100%" }}>
                <Dropdown.Toggle
                  id="dropdown-autoclose-true"
                  style={{ backgroundColor: 'transparent', color: "black", borderColor: "transparent" }}
                  className="no-outline"
                >
                  <div className="sub-title-mobile">
                    {selectedFormation || formationTypes[0]}
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu >
                  {formationTypes.map((item, i) => (
                    <Dropdown.Item key={i} onClick={() => onFormationSelect(item)} className="custom-dropdown-item">
                      <div className="sub-title-mobile">{item}</div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col md={4} className="d-flex justify-content-center align-items-center">
              <button 
                className="gradient-button-no-margin" 
                onClick={handleSaveFormation}
                style={nextGameId === 0 ? { minHeight: "59px", cursor: 'not-allowed'} : {minHeight: "90px"}}
                disabled={nextGameId === 0}
              > 
                <div className="sub-title-mobile">
                  Save Formation 
                </div>
              </button>
            </Col>
            <Col md={4} className="d-flex justify-content-center align-items-center" style={{paddingLeft: '0%'}}>
              <button 
                className="gradient-button-no-margin" 
                onClick={handleSaveDefault}
                style={{minWidth: "190px", minHeight: "90px"}}
              > 
                <div className="sub-title-mobile">
                  Save As Default
                </div>
              </button>
            </Col>
          </Row>
        </Container>
        <Notification 
          notifications={notifications}
          removeNotification={removeNotification}
        />
      </>
    );
  }

  return (
    <>
      <div style={{paddingTop: '2%'}}>
        <Container 
          className='box-shadow-simple-2'
          style={{backgroundColor: '#fff', minWidth: "100px", maxWidth: '500px', width: '95%', minHeight: '25vh', borderRadius: "15px", position: "relative", zIndex: 1}}
        >
          <Row className="justify-content-center" style={{ paddingBottom: '1%',paddingLeft: '5%', paddingRight: '5%', paddingTop: '5%'}}>
            <Dropdown className="gradient-button-no-margin" style={{ minHeight: "59px", width: "100%",zIndex: 5 }}>
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                style={{ 
                  backgroundColor: 'transparent', 
                  color: "black", 
                  borderColor: "transparent", 
                  width: "100%",
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative'
                }}
                className="no-outline"
              >
                <div className="sub-title-mobile" style={{ paddingRight: '1.5rem' }}>
                  {selectedFormation || formationTypes[0]}
                </div>
                <span style={{
                  position: 'absolute',
                  right: '1rem'
                }} className="dropdown-toggle-arrow"></span>
              </Dropdown.Toggle>
              <Dropdown.Menu align="start" style={{ width: "90%", zIndex: 1000}}>
                {formationTypes.map((item, i) => (
                  <Dropdown.Item key={i} onClick={() => onFormationSelect(item)} className="custom-dropdown-item">
                    <div className="sub-title-mobile">{item}</div>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Row>
          <Row className="justify-content-center" style={{ paddingBottom: '1%',paddingLeft: '5%', paddingRight: '5%', paddingTop: '2%'}}>
            <button 
              className="gradient-button-no-margin" 
              onClick={handleSaveFormation}
              style={nextGameId === 0 ? { width: "100%", cursor: 'not-allowed'} : {width: "100%"}}
              disabled={nextGameId === 0}
            > 
              <div className="sub-title-mobile">
                Save Formation
              </div>
            </button>
          </Row>
          <Row className="justify-content-center" style={{ paddingBottom: '5%',paddingLeft: '5%', paddingRight: '5%', paddingTop: '2%'}}>
            <button 
              className="gradient-button-no-margin" 
              onClick={handleSaveDefault}
              style={{width: "100%"}}
            > 
              <div className="sub-title-mobile">
                Save As Default
              </div>
            </button>
          </Row>
        </Container>
      </div>
      <Notification 
        notifications={notifications}
        removeNotification={removeNotification}
      />
    </>
  );
};

export default FormationMenu;
