import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SlideIn from '../animations/slide/SlideIn';
import PlayerDetailCard from '../nft/PlayerDetailCard';
import RoseChart from '../charts/rose/RoseChart';

const PlayerComparison = ({ 
  startAnimation, 
  selectedPlayerData, 
  formationArray, 
  changeComparePlayer, 
  showAttrOf,
  isMobile 
}) => {
  if (!isMobile) {
    return (
      <Row style={{display: 'flex', justifyContent: "center"}}>
        <Col md="auto" xs="auto" style={{display: "flex", justifyContent: "flex-end"}}>
          <SlideIn startAnimation={startAnimation}>
            {startAnimation && (
              <PlayerDetailCard
                cardData={selectedPlayerData[0]}
                cardSelection={selectedPlayerData[0].tokenId}
                formationType={formationArray}
                formationIndex={0}
                cardFunction={changeComparePlayer}
                roleId={"primary"}
                showAttrOf={showAttrOf}
                isMobile={isMobile}
              />
            )}
          </SlideIn>
        </Col>
        {!startAnimation && (
          <div style={{paddingTop: "10%", fontStyle: 'italic'}}>
            Select a player below.
          </div>
        )}
        <Col md="auto" xs="auto" style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
          <SlideIn startAnimation={startAnimation}>
            {startAnimation && (
              <div style={{display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", height: "200px", width: "200px"}}>
                <RoseChart playersData={selectedPlayerData} />
              </div> 
            )}
          </SlideIn>
        </Col>
        <Col md="auto" xs="auto" style={{display: "flex", justifyContent: "flex-start"}}>
          <SlideIn startAnimation={startAnimation}>
            {startAnimation && (
              <PlayerDetailCard
                cardData={selectedPlayerData[1]}
                cardSelection={selectedPlayerData[0].tokenId}
                formationType={formationArray}
                formationIndex={0}
                cardFunction={changeComparePlayer}
                roleId={"secondary"}
                showAttrOf={showAttrOf}
                isMobile={isMobile}
              />
            )}
          </SlideIn>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row style={{display: 'flex', justifyContent: "center"}}>
        <Col md="auto" xs="auto" style={{display: "flex", justifyContent: "flex-end"}}>
          <SlideIn startAnimation={startAnimation}>
            {startAnimation && (
              <PlayerDetailCard
                cardData={selectedPlayerData[0]}
                cardSelection={selectedPlayerData[0].tokenId}
                formationType={formationArray}
                formationIndex={0}
                cardFunction={changeComparePlayer}
                roleId={"primary"}
                showAttrOf={showAttrOf}
                isMobile={isMobile}
              />
            )}
          </SlideIn>
        </Col>
        {!startAnimation && (
          <div style={{paddingTop: "10%", fontStyle: 'italic'}}>
            Select a player below.
          </div>
        )}
        <Col md="auto" xs="auto" style={{display: "flex", justifyContent: "flex-start"}}>
          <SlideIn startAnimation={startAnimation}>
            {startAnimation && (
              <PlayerDetailCard
                cardData={selectedPlayerData[1]}
                cardSelection={selectedPlayerData[0].tokenId}
                formationType={formationArray}
                formationIndex={0}
                cardFunction={changeComparePlayer}
                roleId={"secondary"}
                showAttrOf={showAttrOf}
                isMobile={isMobile}
              />
            )}
          </SlideIn>
        </Col>
      </Row>
      <Row style={{display: 'flex', justifyContent: "center", paddingTop:"5%"}}>
        <Col md="auto" xs="auto" style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
          <SlideIn startAnimation={startAnimation}>
            {startAnimation && (
              <div style={{display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", height: "200px", width: "200px"}}>
                <RoseChart playersData={selectedPlayerData} />
              </div> 
            )}
          </SlideIn>
        </Col>
      </Row>
    </>
  );
};

export default PlayerComparison;
