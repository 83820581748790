import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import BaseLeague from '../leagueOptions/BaseLeague';
import OptimisticLeague from '../leagueOptions/OptimisticLeague';
import ArbitrumLeague from '../leagueOptions/ArbitrumLeague';
import './LeagueSelection.css';

function LeagueSelection({ createTeam, onLeagueSelect, isMobile }) {
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [leagues, setLeagues] = useState([
    {
      name: 'base',
      component: BaseLeague,
      data: { averageTeamCost: 15, numberOfTeams: 11, totalValue: 1000 },
      chainId: 8453,
    },
    {
      name: 'optimistic',
      component: OptimisticLeague,
      chainId: 10,
    },
    {
      name: 'arbitrum',
      component: ArbitrumLeague,
      chainId: 42161,
    },
  ]);
  const containerRef = useRef(null);

  const handleLeagueSelect = (league) => {
    if (selectedLeague === league) {
      setSelectedLeague(null);
      onLeagueSelect(null);
    } else {
      setSelectedLeague(league);
      onLeagueSelect(league);
    }
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setSelectedLeague(null);
      onLeagueSelect(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Container ref={containerRef}>
      <Row 
        className="justify-content-center" 
        style={{
          paddingBottom: isMobile ? "10%" : "5%",
          textAlign: isMobile ? "center" : "left"
        }}
      >
        <Col md="auto">
          <div 
            className="sub-title" 
            style={{
              fontSize: isMobile ? "20pt" : "26pt",
              textAlign: isMobile ? "center" : "left"
            }}
          >
            Select your league
          </div>
        </Col>
      </Row>
      
      {isMobile ? (
        <div className="league-scroll-container">
          <div className="league-scroll">
            {leagues.map(({ name, component: LeagueComponent, data, chainId }) => (
              <div 
                key={name}
                className="league-card"
                onClick={() => handleLeagueSelect(name)} 
                style={{ cursor: 'pointer', paddingTop: name !== 'base' ? '5%' : '0%' }}
              >
                <LeagueComponent 
                  createTeam={createTeam}
                  onLeagueSelect={() => handleLeagueSelect(name)}
                  isSelected={selectedLeague === name}
                  data={data}
                  isMobile={isMobile}
                  chainId={chainId}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Row className="justify-content-center" style={{ paddingTop: "0%" }}>
          {leagues.map(({ name, component: LeagueComponent, data, chainId }) => (
            <Col 
              key={name}
              md={4} 
              onClick={() => handleLeagueSelect(name)} 
              style={{ cursor: 'pointer', paddingTop: isMobile && name !== 'base' ? '5%' : '0%' }}
            >
              <LeagueComponent 
                createTeam={createTeam}
                onLeagueSelect={() => handleLeagueSelect(name)}
                isSelected={selectedLeague === name}
                data={data}
                isMobile={isMobile}
                chainId={chainId}
              />
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
}

export default LeagueSelection;
