import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { GiDiamondHard, GiTeleport } from "react-icons/gi";
import Accordion from '../accordion/Accordion';
import { FaEquals } from 'react-icons/fa';
import { BsArrowUpShort } from 'react-icons/bs';

const TrainingMenu = ({ availableCount, trainedCount, totalCount, trainingResults = [] }) => {
  // Ensure availableCount is never negative
  const displayedAvailableCount = Math.max(0, availableCount);


  const onToggleClick = () => {
    // Handle toggle click if needed
  };

  return (
    <>
      <Container className="box-shadow-simple-2 align-items-center justify-content-center d-flex" 
        style={{backgroundColor: '#fff', minWidth: '100%', width: '100%', minHeight: "80px"}}>
        <Row className="justify-content-center" style={{ paddingBottom: '1%', paddingTop: '1%', maxWidth: "900px", zIndex: 2 }}>
          <Col xs={4} md={4} className="d-flex justify-content-center align-items-center">
            <div style={{ minHeight: "59px", minWidth: "220px", width: "100%" }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
                <div style={{fontSize: '12px', opacity: 0.8, fontWeight: 'bold'}} >Available</div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <div style={{fontSize: '24px'}}><GiTeleport /></div>
                  <div style={{fontSize: '22px', fontWeight: 'bold', paddingTop: '5px'}} className='sub-title-mobile'>
                    {displayedAvailableCount} / {totalCount}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={5} md={4} className="d-flex justify-content-center align-items-center">
            <div style={{ minHeight: "59px", minWidth: "220px", width: "100%" }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
                <div style={{fontSize: '12px', opacity: 0.8, fontWeight: 'bold'}} >Trained Today</div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <div style={{fontSize: '24px'}}><GiDiamondHard/></div>
                  <div style={{fontSize: '22px', fontWeight: 'bold', color: 'yellow', paddingTop: '5px'}} className='sub-title-mobile'>
                    {trainedCount}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {trainingResults.length > 0 && (
        <Accordion 
          onToggleClick={onToggleClick}
          toggleText={
            <div className='sub-title-mobile' style={{ color: availableCount === 0 && trainedCount > 0 ? 'gold' : 'inherit' }}>
              Report
            </div>
          }
        >
          <div className="training-report p-3">
            <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Player</th>
                    <th>Skill</th>
                    <th>Result</th>
                  </tr>
                </thead>
                <tbody>
                  {trainingResults.map((result, index) => (
                    <tr key={index}>
                      <td>{result.title}</td>
                      <td>{result.trainedSkill}</td>
                      <td>
                        {result.success ? 
                          <BsArrowUpShort className="text-success" size={20} /> : 
                          <FaEquals className="text-warning" size={14} />
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Accordion>
      )}
    </>
  );
};

export default TrainingMenu;
