import React, { Component } from 'react';
import { Container,Col,Spinner, Row,Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SignInButton from '../components/SignInButton.js';
import PlayGameModal from '../components/modal/PlayGameModal.js';
import AvailableGamesContainer from '../components/availableGamesContainer/AvailableGamesContainer.js';
import GameViewContainer from '../components/gameViewContainer/GameViewContainer.js';
import NextDivision from '../components/nextDivision/NextDivision.js';
import AnimatedCards from '../components/animatedCards/AnimatedCards';
// helpers
import * as api from '../helpers/api.js';
import * as players from '../helpers/players.js';
import { transformToBNotation } from '../helpers/utils.js';

import backgroundImage from "../static/stadium-pitch-ng-2.png";
import { switchToChain } from '../helpers/coreWeb3.js';

class Schedule extends Component {
  constructor(props) {
    super(props)
    this.state = {
      account: 0,
      formationTypes: [[4,4,2],[4,3,3],[4,5,1],[5,4,1],[3,5,2]],
      homeFormationArray: [4,4,2], //default
      awayFormationArray: [4,4,2], // default
      availableGamesLoading: false,
      showFullSchedule: false,
    }
    this.handlePlayGameClick = this.handlePlayGameClick.bind(this)
    this.getGamesCallback = this.getGamesCallback.bind(this)
  }
  
  async componentDidMount(){
    await this.props.scheduleCallback();
  }

  setStartAnimation(status){
    this.setState({
      startAnimation: status
    })
  } 

  setIsStadiumPlaying(status){
    this.setState({
      isStadiumPlaying: status
    })
  }

  setGameResult(result){
    this.setState({
      gameResult: result
    })
  }

  async componentDidUpdate(prevProps) {
    if (this.props.web3 !== undefined && prevProps !== this.props) {
      if (this.props.ownedTeamLeague == undefined){
        // console.log("no league")
      } else {
        if (!this.state.availableGamesLoading){
          let teamLeague = this.props.ownedTeamLeague;
          this.setAvailableGamesSlides(teamLeague)
          this.setState({availableGamesLoading: true})
        }
        let leagueFullData = await this.props.getLeagueFullData(this.props.ownedTeamLeague);
        this.setState({
          leagueFullData: leagueFullData
        })
      }
    }
  }

  async updateGamesData(leagueFullData) {
    if (!leagueFullData?.schedule?.length) {
      return;
    }
    // console.log("schedule: ", leagueFullData.schedule);

    // Split the schedule into three parts
    const third = Math.ceil(leagueFullData.schedule.length / 3);
    const firstPart = leagueFullData.schedule.slice(0, third);
    const secondPart = leagueFullData.schedule.slice(third, 2 * third);
    const thirdPart = leagueFullData.schedule.slice(2 * third);
    
    // Fetch all games by league from the database
    let existingGames = await api.getAllGamesByLeague(this.props.ownedTeamChainId, this.props.ownedTeamLeague);
    // console.log("existingGames: ", existingGames);
    // Check if the number of existing games is less than 55
    if (existingGames.length < 55) {
      await this.addGames(firstPart);
      await this.addGames(secondPart);
      await this.addGames(thirdPart);
    }

    // Update scores for all games in the schedule for each part
    const scheduleParts = [firstPart, secondPart, thirdPart];
    for (const part of scheduleParts) {
      let updateScorePromises = part.map(game =>
        api.updateGameScore(game.game_id, this.props.ownedTeamChainId, this.props.ownedTeamLeague)
      );
      await Promise.all(updateScorePromises);
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }

  async addGames(gamesPart) {
    let addGamePromises = gamesPart.map(game => 
      api.addGame(game[3], this.props.ownedTeamChainId, this.props.ownedTeamLeague)
    );
    await Promise.all(addGamePromises);
    await new Promise(resolve => setTimeout(resolve, 1000));
  }

  async setAvailableGamesSlides(teamLeague) {
    var availableGames = []
    if (this.props.availableGames === undefined){
      availableGames = await this.props.getAvailableGames(teamLeague);
    } else {
      availableGames = this.props.availableGames
    }
    console.log("Available games: ", availableGames);
    let availableGamesSlides = availableGames.map(game => {
      let imageUrlHome = players.uriToImage(game.teamUriHome);
      let imageUrlAway = players.uriToImage(game.teamUriAway);
      
      // Check if team names are empty and transform if necessary
      let teamNameHome = game.teamNameHome || (game.home_team_id.toString().startsWith('8453') ? `Team #${transformToBNotation(game.home_team_id.toString())}` : `Team #${game.home_team_id}`);
      let teamNameAway = game.teamNameAway || (game.away_team_id.toString().startsWith('8453') ? `Team #${transformToBNotation(game.away_team_id.toString())}` : `Team #${game.away_team_id}`);

      return {
        imageUrlHome: `${imageUrlHome}`, 
        imageUrlAway: `${imageUrlAway}`,
        teamNameHome: `${teamNameHome}`,
        teamNameAway: `${teamNameAway}`, 
        buttonText: "Play",
        gameStart: `${game.game_start_time}`,
        gameId: `${game.game_id}`
      };
    });
    this.setState({
      availableGames: availableGames,
      availableGamesSlides: availableGamesSlides
    })
    // console.log(availableGamesSlides)
    return availableGames;
  }

  stringToArrayOfNumbers(str) {
    return str.split('-').map(Number);
  }

  setSelectedItem(item){
    let formationArray = this.stringToArrayOfNumbers(item)
    this.setState({
      selectedFormation: item,
      formationArray: formationArray
    })
  }

  getOpponent(){
    if (this.props.nextGameInfo[0] === this.props.ownedTeamId){
      return this.props.nextGameInfo[1]
    } else {
      return this.props.nextGameInfo[0] 
    }
  }

  generateDate(time_){
    // roundup to :30
    return new Date((Number(time_) +1700) * 1000).toLocaleDateString('en-GB', { hour: '2-digit', minute: '2-digit' });
  };

  getGames = async (teamLeague) => {
    const availableGames = this.props.availableGames || await this.props.getAvailableGames(teamLeague);
    console.log("Available games: ", availableGames);
    
    return availableGames.map(game => {
      let imageUrlHome = players.uriToImage(game.teamUriHome);
      let imageUrlAway = players.uriToImage(game.teamUriAway);
      
      let teamNameHome = game.teamNameHome || (game.home_team_id.toString().startsWith('8453') ? 
        `Team #${transformToBNotation(game.home_team_id.toString())}` : 
        `Team #${game.home_team_id}`);
      let teamNameAway = game.teamNameAway || (game.away_team_id.toString().startsWith('8453') ? 
        `Team #${transformToBNotation(game.away_team_id.toString())}` : 
        `Team #${game.away_team_id}`);

      return {
        imageUrlHome,
        imageUrlAway,
        teamNameHome,
        teamNameAway,
        buttonText: "Watch",
        gameStart: game.game_start_time,
        gameId: game.game_id
      };
    });
  }

  getGamesCallback(games){
    this.setState({
      availableGames: games
    })
  }

  handleShowSetFormation(){
    this.setState({
      showSetFormation: true 
    })
  }

  async handleShowPastGames(){
    this.setState({
      pastGamesLoaded: false,
    });

    if (!this.state.pastGamesClicked) {
      await this.updateGamesData(this.state.leagueFullData);
      this.setState({
        pastGamesClicked: true,
        pastGamesLoaded: true,
        teamSchedule: this.state.pastGames.concat(this.state.teamSchedule),
      });
    }
  }

  async handleShowFullSchedule(){
    this.setState((prevState) => ({
      showFullSchedule: !prevState.showFullSchedule,
    }));
    if (this.state.showFullSchedule){
      this.setState({
        showFullSchedule: false
      })
      return
    }
    this.setState({
      scheduleLoading: true,
    })

    let calendar = await api.getLeagueCalendar(this.props.ownedTeamLeague, this.props.ownedTeamChainId);
    
    // Check and update games with no result that are past the start date
    const currentTime = Math.floor(Date.now() / 1000);
    const gamesToUpdate = calendar.filter(game => 
      game.game_start_time < currentTime && !game.result
    );
    // console.log("calendar: ", calendar);
    // console.log("gamesToUpdate: ", gamesToUpdate);
    if (gamesToUpdate.length > 0) {
      await this.updateGamesData({ schedule: gamesToUpdate });
    }

    let teamSchedule = []
    let pastGames = []
    // console.log(leagueFullData)
    calendar.map((game) => {
        if(game.home_team_id == this.props.ownedTeamId || game.away_team_id == this.props.ownedTeamId){
          if (game.game_start_time > Math.floor(Date.now() / 1000)){
            // console.log(game)
            teamSchedule.push(game)
          } else {
            pastGames.push(game)
          }
        }
      }
    )
    // // console.log(pastGames)
    
    const sortedSchedule = teamSchedule.slice().sort(this.sortFunction);
    const pastGamesSorted = pastGames.slice().sort(this.sortFunction);
    teamSchedule = Promise.all(sortedSchedule.map(async (game, i) => {
      const homeTeamName = game.home_team_name || (game.home_team_id.toString().startsWith('8453') ? transformToBNotation(game.home_team_id.toString()) : game.home_team_id);
      const awayTeamName = game.away_team_name || (game.away_team_id.toString().startsWith('8453') ? transformToBNotation(game.away_team_id.toString()) : game.away_team_id);
      return(
        <>
        <tr key={i} >
          <td>{homeTeamName}</td>
          <td>{awayTeamName}</td>
          <td>{this.generateDate(game.game_start_time)}</td>
          <td>{game.game_id}</td>
        </tr>
        </>
      )
    })).then(result => {
      this.setState({
        scheduleLoading: false,
        teamSchedule: result,
      })
    });
    pastGames = Promise.all(pastGamesSorted.map(async (game, i) => {
      const homeTeamName = game.home_team_name || (game.home_team_id.toString().startsWith('8453') ? transformToBNotation(game.home_team_id.toString()) : game.home_team_id);
      const awayTeamName = game.away_team_name || (game.away_team_id.toString().startsWith('8453') ? transformToBNotation(game.away_team_id.toString()) : game.away_team_id);
      return(
        <>
        <tr key={i} >
          <td>{homeTeamName}</td>
          <td>{awayTeamName}</td>
          <td>{this.generateDate(game.game_start_time)}</td>
          {!this.props.isMobile && <td>{game.game_id}</td>}
          <td>{game.result}</td>
        </tr>
        </>
      )
    })).then(result => {
      this.setState({
        pastGamesLoaded: true,
        pastGames: result
      })
    });

    // console.log(teamSchedule)
    this.setState({
      scheduleLoaded: true,
      showFullSchedule: true
    })
  }

  sortFunction(a, b) {
    return a[2] - b[2];
  }

  async handlePlayGameClick(gameId,index){
    console.log(gameId,this.state.availableGames)
    let gameInfo = this.state.availableGames.find(game => game.game_id == gameId);
    // console.log(gameInfo)
    this.setState({selectedGame: gameInfo})
    this.setIsStadiumPlaying(true)
    if (!this.state.startAnimation){
      this.setStartAnimation(true)
    }
    // this.setState({ showPlayGameModal: true })
    let homeFormation = await this.props.loadFormationById(gameInfo.home_team_id)// (this.props.nextGameInfo[0]
    let awayFormation = await this.props.loadFormationById(gameInfo.away_team_id) //(this.props.nextGameInfo[1]
    // console.log(homeFormation, awayFormation)
    let homeFormationArray = this.state.formationTypes[Number(homeFormation[0])]
    let awayFormationArray = this.state.formationTypes[Number(awayFormation[0])]
    this.setState({
      homeFormation: homeFormation,
      awayFormation: awayFormation,
      homeFormationArray: homeFormationArray,
      awayFormationArray: awayFormationArray,
    })
    var result = {}
    try{
      result = await this.props.handlePlayGame(gameId)
      // result = {0: "7", 1: "3", 2: "1701162143", 3:"3", 4: "2", teamNameHome: "Xatt Lahmar FC", teamNameAway: "ChadBallers"}
      let teams = {
        teamNameHome: this.state.selectedGame.home_team_name, 
        teamNameAway: this.state.selectedGame.away_team_name
      };
      result = {
        0: result[0],
        1: result[1],
        2: result[2],
        3: result[3],
        4: result[4],
        teamNameHome: teams.teamNameHome,
        teamNameAway: teams.teamNameAway
      };
      this.setGameResult(result)
      this.updateGamesData(this.state.leagueFullData);
      this.setAvailableGamesSlides()
      this.setIsStadiumPlaying(false)
      this.setState({ generalModalStatusGame: "success" })
    }
    catch(error){
      this.setGameResult("error")
      this.setIsStadiumPlaying(false)
      this.setState({ showPlayGameModal: false })
      this.setState({ generalModalStatusGame: "" })
    }
    let leagueData = await this.props.getLeagueData(this.props.ownedTeamLeague);
    // var gameCommentary = await api.getGameCommentary(result, leagueData)
    // console.log(gameCommentary)
    // await api.speakGameCommentary(result, gameCommentary.join('\n'))
    // this.setState({
    //   gameCommentary: gameCommentary
    // })
  }

  async getTeamName(inputValue) {
    // console.log(inputValue)
    if (this.props.teamsData !== undefined){
      for(let i = 0; i < this.props.teamsData.length; i++){
        let game = this.props.teamsData[i];
        if (game[1] == inputValue) {
          // console.log(game[2])
          if (game[2].length > 0){
            return game[2]; 
          } else {
            let name = await this.props.getTeamName(inputValue);
            // console.log(name)
            return name;
          }
        } 
      }
  
      let name = await this.props.getTeamName(inputValue);
      // console.log(name)
      return name;
          } else {
      let name = await this.props.getTeamName(inputValue);
      // console.log(name)
      return name;
    }
  }

  render(){
    return (
      <>
      <div style={ !this.props.isMobile ? {} : {maxWidth: "500px"}}>
        <div style={!this.props.isMobile ? (this.props.ownedTeamId !== undefined && this.props.ownedTeamId > 0 ? {
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${backgroundImage})`, 
            backgroundBlendMode: 'soft-light',
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat',
            minHeight: '150vh',
            minWidth: '100%'
          } : {}) : (
            this.props.ownedTeamId !== undefined && this.props.ownedTeamId > 0 ? {
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${backgroundImage})`, 
              backgroundBlendMode: 'soft-light',
              backgroundSize: 'cover', 
              backgroundRepeat: 'no-repeat',
              minHeight: '150vh',
              minWidth: '100%'
            } : {})
        }>
          <GameViewContainer
            startAnimation={this.state.startAnimation}
            handlePlayGameClick={this.handlePlayGameClick}
            selectedGame={this.state.selectedGame}
            gameResult={this.state.gameResult}
            gameCommentary={this.props.gameCommentary}
            homeFormation={this.props.homeFormation}
            awayFormation={this.props.awayFormation}
            homeFormationArray={this.state.homeFormationArray}
            awayFormationArray={this.state.awayFormationArray}
            teamsData={this.props.teamsData}
            getLeagueData={this.props.getLeagueData}
            isMobile={this.props.isMobile}
            gameStarted={this.state.gameStarted}
            startGame={this.startGame}
            gameStatus={this.state.gameStatus}
            setGameStarted={this.setGameStarted}
          />
          {this.props.ownedTeamId > 0 && this.state.selectedGame == undefined ? (
            <div style={{ display: 'flex', justifyContent: 'center', padding: '2% 2% 0 2%' }}>
              <AvailableGamesContainer 
                startAnimation={this.state.startAnimation}
                slides={this.state.availableGamesSlides}
                buttonFunc={this.handlePlayGameClick}
                getNextDivisionTeams={this.props.getNextDivisionTeams}
                leagueId={this.props.ownedTeamLeague}
                ownedTeamId={this.props.ownedTeamId}
                isMobile={this.props.isMobile}
              />
            </div>
          ) : (
            // Show animated cards for non-logged in users
            !this.props.account && (
              <>
              <div style={ !this.props.isMobile ? {paddingTop: "0px", paddingLeft: "0%"} : {paddingTop: "5%"}}>
                <Row className="justify-content-center" style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className='sub-title-mobile' style={{paddingBottom: "0%", paddingTop: "2%"}}>
                    Live Games
                  </div>
                </Row>
              </div>
              <div style={{ padding: '2% 2% 0 2%' }}>
                <AnimatedCards 
                  getGames={this.getGames}
                  getGamesCallback={this.getGamesCallback}
                  ownedTeamLeague={2}
                  playGame={this.handlePlayGameClick}
                />
              </div>
              {!this.props.account && (
                <Row className="justify-content-center" style={{ paddingTop: '5%'}}>
                  <Col md="auto" xs="auto">
                    Sign in to display your schedule.
                    <SignInButton
                      loginButtonFallback={this.props.loginButtonFallback}
                      customText={'Sign in'}
                      accountListenerFallback={this.props.accountListenerFallback}
                      isMobile={this.props.isMobile}
                    />
                  </Col>
                </Row>
              )}
              </>
            )
          )}

          {this.props.account !== undefined && (
            <>
              <div style={{ display: 'flex', justifyContent: 'center', padding: '1% 0' }}>
                <button 
                  className="gradient-button" 
                  onClick={() => this.handleShowFullSchedule()}
                >
                  <div className="sub-title-mobile">
                    {this.state.showFullSchedule ? "Hide Full Schedule" : "Show Full Schedule"}
                  </div>
                </button>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', padding: '1% 0 5% 0' }}>
                {this.state.scheduleLoading && <Spinner animation="border" variant="light" />}
              </div>
            </>
          )}
          {(this.props.nextGameId !== undefined && this.props.nextGameId > 0 && this.props.nextGameInfo !== undefined) ? (
            <>
            <Row style={{display: 'flex', justifyContent: 'center', paddingRight:"2%", paddingTop: '1%'}}>
              <span className='table-title'>
                Next Game
              </span>
            </Row>
            <Row style={{paddingTop:'1%', display: 'flex', justifyContent: 'center',paddingRight:"3%" }}>
              <span className="button-bold">
              {this.state.homeTeamName} 
              </span>
              <span style={{paddingTop:'0.5%', display: 'flex', justifyContent: 'center'}}>
                vs
              </span>
              <span className="button-bold">
              {this.state.awayTeamName}
              </span>
            </Row>
            <Row style={{paddingTop:'3%', display: 'flex', justifyContent: 'center', paddingRight:"3%" }}>
              <span className="button-bold">
                Kick Off: {this.generateDate(this.props.nextGameInfo[2])} 
              </span>
            </Row>
            <Row style={{paddingTop:'3%', display: 'flex', justifyContent: 'center', paddingRight:"3%" }}>
              <Col md="auto" xs="auto" >
                <Link to="/formation" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <button className="button-1b" onClick={() => this.handleShowSetFormation()}>
                    Set Game Formation
                  </button>
                </Link>
              </Col>
            </Row>
          </>
          ):
          (
            <>
            {this.props.account !== undefined && (
              <>
               {!this.props.ownedTeamId > 0 && (
                <Row className="justify-content-center" style={{ paddingTop: '1%'}}>
                  <Col md="auto" xs="auto">
                    <Link to="/app" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <button className="button-1">
                        Create a team to play a game.
                      </button>
                    </Link>
                  </Col>
                </Row>
              )}
              </>
            )
          }
            </>
        )}
        {this.state.showFullSchedule && this.state.pastGamesLoaded && (
          <Row style={{ paddingBottom: "3%" }}>
            <Container style={!this.props.isMobile ? { backgroundColor: 'rgb(250, 255, 249)', maxWidth: '900px', width: '90%', height: '20%', borderRadius: "10px", paddingLeft: '10px', paddingRight: '10px' } : null}>
              <Row className="justify-content-center" style={this.props.isMobile ? { paddingBottom: '5%', paddingTop: '5%', paddingLeft: '5%', paddingRight: '5%' } : { paddingBottom: '1%', paddingTop: '1%', paddingLeft: '1%', paddingRight: '1%' }}>
                <Table striped bordered hover style={{ backgroundColor: 'white' }}>
                  <thead>
                    <tr>
                      <th>Home Team</th>
                      <th>Away Team</th>
                      <th>Start Time (UTC)</th>
                      {!this.props.isMobile && <th>Game Id</th>}
                      <th>Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.pastGames && this.state.pastGames.length > 0 ? (
                      this.state.pastGames
                    ) : (
                      <tr>
                        <td colSpan="4" style={{ fontStyle: 'italic', textAlign: 'center' }}>
                          No past games available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Row>
              <Row className="justify-content-center" style={this.props.isMobile ? { paddingBottom: '5%', paddingTop: '5%', paddingLeft: '5%', paddingRight: '5%' } : { paddingBottom: '1%', paddingTop: '1%', paddingLeft: '1%', paddingRight: '1%' }}>
                <Table striped bordered hover style={{ backgroundColor: 'white' }}>
                  <thead>
                    <tr>
                      <th>Home Team</th>
                      <th>Away Team</th>
                      <th>Start Time (UTC)</th>
                      <th>Game Id</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.teamSchedule && this.state.teamSchedule.length > 0 ? (
                      this.state.teamSchedule
                    ) : (
                      <tr>
                        <td colSpan="4" style={{ fontStyle: 'italic', textAlign: 'center' }}>
                          No next games available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Row>
            </Container>
          </Row>
        )}
        {this.props.nextDivisionTeams && (
          <div className="justify-content-center" style={{ display: 'flex', justifyContent: 'center', paddingTop: '1%' }}>
            <NextDivision
              ids={this.props.nextDivisionTeams} 
              handleCreateDivision={this.props.handleCreateDivision}
              handleInvite={this.props.generateInvite}
              onSelectedTeamsChange={this.props.handleSelectedTeamsChange}
              getTeamNameById={this.props.getTeamNameById}
              chainId={this.props.ownedTeamChainId}
              isMobile={this.props.isMobile}
            />
          </div>
        )}
      </div>
      <PlayGameModal 
        show={this.state.showPlayGameModal}
        onHide={() => this.setState({ showPlayGameModal: false })}
        handlePlayGame={this.handlePlayGameClick}
        statusMsg={"Game played! Check the result in your schedule below..."}
        status={this.state.generalModalStatusGame}
      />
      </div>
      </>
    );
  }
}

export default Schedule;
