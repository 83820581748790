import React, { Component } from 'react'
import { Button,Col, Card, Dropdown,  Form, Figure, ListGroup,Row, Container} from 'react-bootstrap'

import INFTContract from '../../contracts/INFTContract.json'
import LeagueTable from './LeagueTable';

import Web3 from 'web3';

class LeaguesGrid extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef();
    this.state = {
      loaded: false,
      uris: [],
      ownedNFTs: [],
      cardsData: [],
      formation: [],
      formIndexes: [[1,4],[4,9],[9,11]],
      selectedLeague: 0,
      numLeagues: [1]
    }
  }

    async componentDidMount() {
        this.init()
    }

    async init(){
        let numLeagues = await this.props.getNumberOfLeagues()
        if (numLeagues != undefined){
            this.setState({
                numLeagues: numLeagues
            })
        }
    }

    handleLeagueClick(index){
        if (this.state.selectedLeague == index){
            this.setState({
                selectedLeague: 0
            })
        } else {
            this.setState({
                selectedLeague: index
            })
        }
    }
    

  render() {
    // console.log(this.props.teamsData)
    return(
      <>
      <Container style={ !this.props.isMobile ? {maxWidth: "800px", paddingBottom: "2%"}:null}>
        <ListGroup >
            {this.state.selectedLeague > 0 ? (
                <>
                <ListGroup.Item style={{ border: '1px solid transparent', marginBottom: '5px' }}>
                    <div style={this.props.isMobile ? {paddingLeft:"5%"}:null}>
                        Division {this.state.selectedLeague}
                        <span style={{ float: 'right' }}>
                            <button className="button-1-nopad" onClick={() => this.handleLeagueClick(0)}> 
                                Hide 
                            </button>
                        </span>
                    </div>
                </ListGroup.Item>
                <div className="justify-content-center" style={{ paddingTop: '1%', overflowX: 'auto'}}>
                    <LeagueTable
                        leagueId={this.state.selectedLeague}
                        teamsData = {this.props.teamsData}
                        getLeagueData={this.props.getLeagueData}
                        isMobile={this.props.isMobile}
                    />
                </div>
                </>
            ):(this.state.numLeagues.map((item,listIndex) => (
                <ListGroup.Item 
                    key={listIndex} 
                    style={{ 
                        border: '1px solid transparent', 
                        borderBottom: '1px solid lightgrey', 
                        marginBottom: '5px',
                        ...(!this.props.isMobile ? { display: 'flex', justifyContent: 'space-between',alignItems: 'center'}:{ display: 'flex', justifyContent: 'space-between',alignItems: 'center', maxWidth: "99vw" }) 
                    }}>
                    Division {item} 
                    <span style={{ float: 'right' }}>
                        <button className="button-1" onClick={() => this.handleLeagueClick(item)}> 
                            Show 
                        </button>
                    </span>
                </ListGroup.Item>
            )
            ))} 
        </ListGroup>
      </Container>
      </>
    );
}
}

export default LeaguesGrid;